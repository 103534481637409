import React from "react";
import BaseContainer from "core/BaseContainer/";
import oResourceBundle from "app/i18n/";
import "./index.scss";
import Button from "../../../../core/components/Button";
import { connect } from "react-redux";
import * as CONSTANTS from "../../../AppConfig/constants";
import marathonbg from "app/resources/assets/thumbnail/marathon-bg1.png";
import winnercup from "app/resources/assets/thumbnail/winner-cup.png";
import * as appURLs from "app/AppConfig/urlConfig";
import * as common from "app/utility/common";

class MarathonWinnerPage extends BaseContainer {
  constructor(props) {
    super(props);
    this.state={
      winnersData:[]
    }
  }

  componentDidMount() {
    // For initial data
    this.fetchData();
  }
  fetchData() {
    let url = appURLs.MARATHON_GET_FIVE
    // const oUserToken = common.getServerCookie(CONSTANTS.COOKIE_USER_TOKEN);
    // let authToken=JSON.parse(oUserToken)
    // let access_token=authToken.authToken
    fetch(url, {
      method: "GET",
      dataType: "JSON",
      headers: {
        "Content-Type": "application/json;",
        // Authorization:`Bearer ${access_token}`,
      },
    })
      .then((resp) => {
        return resp.json();
      })
      .then((data) => {
        this.setState({ winnersData: data });
        return data;
      })
      .catch((error) => {
        console.log(error, "catch the hoop");
      });
  }

  


  render() {
        return (
      <React.Fragment>
       <div className="marathon-winner-container">
        <div className="marathon-bg">
            <img src={marathonbg} className="img-fluid" />
            <h1>{oResourceBundle.winners}</h1>
          </div>
          <div className="marathon-winner-section winner-container">
            {this.state.winnersData && this.state.winnersData.map((item,index)=>(
            <div className="marathon-winner-item" key={index}>
              <div className="image-content"><img src={winnercup} /></div>
              <p className="winner-name">{item.firstName}</p>
            </div>
            ))}
          </div>
          {/* <p className="stay-text">Stay connected</p> */}
          <p className="stay-text">{oResourceBundle.stayconnected}</p>
       </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    locale: state.locale,
  };
};
export default connect(mapStateToProps)(MarathonWinnerPage);
