/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */

import React from "react";
import { Link, withRouter } from "react-router-dom";
import * as appURLs from "app/AppConfig/urlConfig";
import BaseContainer from "core/BaseContainer/";
import { connect } from "react-redux";
import * as CONSTANTS from "../../../AppConfig/constants";
import * as actionTypes from "app/store/action/";
import * as common from "app/utility/common";
import Input from "../../../../core/components/Input/";
import Button from "../../../../core/components/Button/";
import Dialog from "core/components/Dialog";
import oResourceBundle from "app/i18n/";
import CheckBox from "core/components/Checkbox";
import withTracker from "core/GoogleAnalytics/";
import SelectBox from "core/components/SelectBox";
import { sendEvents } from "core/GoogleAnalytics/";
import Label from "core/components/Label";
import { isUserLoggedIn, isValidEmail } from "app/utility/common";
import Spinner from "core/components/Spinner";
import PhoneInput from "../../components/PhoneInput/";
import { toast } from "core/components/Toaster/";
import ReactHtmlParser from "react-html-parser";
import { CleverTap_UserEvents } from "core/CleverTap";
import marathonbg from "app/resources/assets/thumbnail/marathon-bg1.png";
import { SET_SELECTEDCOUNTRY,SET_EVENTCOUNTRY} from "../../../store/action/actions";

//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";

import "./index.scss";

let maxLength=60
let defaultCountryName;
const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
class MarathonForm extends BaseContainer {
  /**
   * Represents MyAccount.
   * @constructor
   * @param {Object} props - Properties of the object.
   */
  constructor(props) {
    super(props);
    this.state = {
      userId: common.getUserId(),
      Uname: "",
      fname: "",
      lname: "",
      email: "",
      password: "",
      mobile: "",
      newsletter: false,
      newsletter1: false,
      newsletter2: false,
      newsletter3: false,
      newsletter4: false,
      promotions: false,
      playnext: false,
      dialogMessage: {},
      showDialog: false,
      errorMessage: {
        fname: "",
        lname: "",
        email: "",
        password: "",
        mobile: "",
      },
      showCountrySelectBox: false,
      showLanguageSelectBox: false,
      country: "",
      selectedCountryCode: null,
      language: "",
      selectedLanguageCode: "",
      bEnableUpdateBtn: false,
      showErrorDialog: false,
      showErrorDialogMessage: "",
      emailErrorText: "",
      bEmailValid: false,
      bMobileValid: false,
      showMobileVerification: false,
      checkboxChanged1: false,
      checkboxChanged2: false,
      checkboxChanged3: false,
      isAdult: false,
      isRecommend: false,
      termsOfUse: false,
      performance: "",
      advertising: "",
      googleAnalytics: "",
      cleverTap: "",
      firebase: "",
      appFlyer: "",
      aique: "",
      googleAds: "",
      facebookAds: "",
      isGdprAccepted: "",
    };
  }

  componentDidMount() {
    this.fnScrollToTop();
    if (isUserLoggedIn()) {
      this.props.fnFetchCountryList(this.props.locale);
      this.props.fnFetchUserDetails(null, null, true);
    } else {
      this.props.history.push(`/${this.props.locale}/${CONSTANTS.LOGIN}`);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.loginDetails && !this.props.loginDetails["bSuccessful"]) {
      this.props.history.push(`/${this.props.locale}/${CONSTANTS.LOGIN}`);
    }
    if (this.props.locale !== prevProps.locale) {
      this.props.fnFetchCountryList(this.props.locale);
    }
  }
  //Reset all the states if navigating back to the page
  static getDerivedStateFromProps(nextProps, nextState) {
    if (
      nextState.prevProps &&
      nextProps.oUserAccountDetails !== nextState.prevProps.oUserAccountDetails
    ) {
      return {
        fname: nextProps.oUserAccountDetails.firstName,
        Uname : nextProps.oUserAccountDetails.nickName,
        lname: nextProps.oUserAccountDetails.lastName,
        email: nextProps.oUserAccountDetails.email,
        mobile: nextProps.oUserAccountDetails.phoneNumber,
        savedMobile: nextProps.oUserAccountDetails.phoneNumber,
        newsletter: nextProps.oUserAccountDetails.newslettersEnabled,
        // newsletter: true,
        promotions: nextProps.oUserAccountDetails.promotionsEnabled,
        country:
          nextProps.oUserAccountDetails.countryName == ""
            ? null
            : nextProps.oUserAccountDetails.countryName,
        selectedCountryCode: nextProps.oUserAccountDetails.countryId || null,
        language: nextProps.oUserAccountDetails.languageName,
        selectedLanguageCode: nextProps.oUserAccountDetails.languageId,
        // newsletter1: nextProps.oUserAccountDetails.privacyPolicy,
        newsletter1: true,
        newsletter3: true,
        newsletter2: true,
        // newsletter3: nextProps.oUserAccountDetails.isAdult,
        // newsletter2: nextProps.oUserAccountDetails.isRecommend,
        playnext: false,
        prevProps: nextProps,
        performance: nextProps.oUserAccountDetails.performance,
        advertising: nextProps.oUserAccountDetails.advertising,
        googleAnalytics: nextProps.oUserAccountDetails.googleAnalytics,
        cleverTap: nextProps.oUserAccountDetails.cleverTap,
        firebase: nextProps.oUserAccountDetails.firebase,
        appFlyer: nextProps.oUserAccountDetails.appFlyer,
        aique: nextProps.oUserAccountDetails.aique,
        googleAds: nextProps.oUserAccountDetails.googleAds,
        facebookAds: nextProps.oUserAccountDetails.facebookAds,
        isGdprAccepted: nextProps.oUserAccountDetails.isGdprAccepted,
      };
    }
    // Return null to indicate no change to state.
    return {
      prevProps: nextProps,
    };
  }

  /**
   * Component Name - MyAccount
   * Update button button enable/ disable
   * @param {null}
   */
  fnSetUpdateButtonEnabled() {
    if (
      this.state.fname &&
      this.state.fname.length > 0 &&
      this.state.Uname && this.state.Uname.length > 0 &&
      this.state.lname && this.state.lname.length > 0 && (this.state.email && this.state.email.length >0)  && (this.state.country !== "") && this.state.mobile && this.state.newsletter1 && this.state.newsletter2 && this.state.newsletter3
    ) {
      if(this.state.bEmailValid || this.state.bMobileValid){
        this.setState({ bEnableUpdateBtn: true });

      }
    } else {
      this.setState({ bEnableUpdateBtn: false });
    }
  }

  /**
   * Component Name - MyAccount
   * Form Inputs Changes, Updating the State.
   * @param {object} eve - Event hanlder
   */
  handleFormInputs(eve) {
    const { name, value } = eve.target;
    this.setState({ [name]: value }, this.fnSetUpdateButtonEnabled);
  }
  /**
   * Component Name - MyAccount
   * Form Inputs Changes, Updating the State and check for the validations.
   * @param {object} eve - Event hanlder
   */
  handleEmailOnChange(event) {
    // console.log("Coming to this event------",event)
    const text = event.target.value;
    // console.log("text---------",text)
    this.setState({
      email: text
    });
    if (common.isValidEmail(text)) {
      this.setState(
        {
          bEmailValid: true
        }, this.fnSetUpdateButtonEnabled
       
      );
    } else {
      this.setState(
        {
          bEmailValid: false
        }, this.fnSetUpdateButtonEnabled
        
      );
    }
  }
  /**
   * Component Name - MyAccount
   *  Checkbox Changes, Updating the State.
   * @param {object} oEvent - Event hanlder
   */
  handleCheckBox(oEvent) {
    this.setState(
      {
        [oEvent.target.name]: oEvent.target.checked,
        checkboxChanged: true,
      },
      this.fnSetUpdateButtonEnabled
    );
  }
  handleCheckBox1(oEvent) {
    this.setState(
      {
        [oEvent.target.name]: oEvent.target.checked,
        checkboxChanged1: true,
        termsOfUse: true,
      },
      this.fnSetUpdateButtonEnabled
    );
  }
  handleCheckBox2(oEvent) {
    this.setState(
      {
        [oEvent.target.name]: oEvent.target.checked,
        checkboxChanged2: true,
        isAdult: true,
      },
      this.fnSetUpdateButtonEnabled
    );
  }
  handleCheckBox3(oEvent) {
    this.setState(
      {
        [oEvent.target.name]: oEvent.target.checked,
        checkboxChanged3: true,
        isRecommend: true,
      },
      this.fnSetUpdateButtonEnabled
    );
  }
  handleCheckBox4(oEvent) {
    this.setState(
      {
        [oEvent.target.name]: oEvent.target.checked,
        checkboxChanged4: true,
      },
      this.fnSetUpdateButtonEnabled
    );
  }

  /**
   * Component Name - MyAccount
   * show country drop down
   * @param {null}
   */
  countryShowToggle(oEvent) {
    this.setState((newState) => ({
      showCountrySelectBox: !newState.showCountrySelectBox,
      showLanguageSelectBox: false,
    }));
    oEvent.stopPropagation();
  }
  /**
   * Component Name - MyAccount
   * show language drop down
   * @param {null}
   */
  languageShowToggle(oEvent) {
    this.setState((newState) => ({
      showLanguageSelectBox: !newState.showLanguageSelectBox,
      showCountrySelectBox: false,
    }));
    oEvent.stopPropagation();
  }

  onContainerClick() {
    this.setState({
      showLanguageSelectBox: false,
      showCountrySelectBox: false,
    });
  }
  /**
   * Component Name - MyAccount
   * country drop down selection change
   * @param {null}
   */
  handleChangeCountrySelection(oEvent, index, sSelectedCountryKey) {
    // console.log("event raising----")
    this.setState(
      {
        country: oEvent.target.innerText,
        selectedCountryCode: sSelectedCountryKey,
        countryChanged: true,
      },
      this.fnSetUpdateButtonEnabled
    );
    this.props.setEventCountry(true)

  }
  /**
   * Component Name - MyAccount
   * Language drop down selection change
   * @param {null}
   */
  handleChangeLanguageSelection(oEvent, index, sSelectedCountryKey) {
    this.setState(
      {
        language: oEvent.target.innerText,
        selectedLanguageCode: sSelectedCountryKey,
        languageChanged: true,
      },
      this.fnSetUpdateButtonEnabled
    );
  }
  /**
   * Component Name - MyAccount
   * Sign out all button press
   * @param {null}
   */
  showSignOutAllConfirm() {
    this.setState({
      showDialog: true,
    });
  }

  /**
   * Component Name - MyAccount
   * Sign out all button press
   * @param {null}
   */
  showSignOutAllCancel() {
    this.setState({
      showDialog: false,
    });
  }

  /**
   * Component Name - MyAccount
   * Hide error dialog
   * @param {null}
   */
  hideErrorDialog() {
    this.setState({
      showErrorDialog: false,
    });
  }
  /**
   * Component Name - MyAccount
   * Update accout button press
   * @param {null}
   */
  handleUpdateBtnClicked() {
    this.setState({ bEnableUpdateBtn: false });
   
    let url = appURLs.MARATHON_REGISTER
      const oUserToken = common.getServerCookie(CONSTANTS.COOKIE_USER_TOKEN);
    let authToken = JSON.parse(oUserToken)
    let access_token = authToken.authToken
    let totalData = {
      id: this.state.userId,
      country: null,
      firstName: this.state.fname,
      languageId: this.state.selectedLanguageCode,
      lastName: this.state.lname,
      email: this.state.email,
      passwordHash: "12345678",
      userName: this.state.Uname,
      phoneNumber: this.state.mobile,
      callingCode: this.props.dialCode,
      nationalNumber: this.props.nationalNumber,
      countryName: (this.state.country!=undefined || this.state.country!=null) ? this.state.country : defaultCountryName,
    };
      fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
               Authorization: `Bearer ${access_token}`,
 
      },
      body: JSON.stringify(totalData),
    }).then((result) => {
      result.json().then((res) => {
        // console.log("res", res);
        if(res.status==1){
          let ga_category =  CONSTANTS.GA_CONTEST_CATEGORY;
          let ga_action = CONSTANTS.GA_CONTEST_ACTION;
          sendEvents(
            ga_category,
            ga_action,
            "Marathon_5"
          );

          toast.dismiss();
          toast.success(oResourceBundle.marathon_toast, {
            position: toast.POSITION.BOTTOM_CENTER
          });
          this.props.history.push(
            `/${this.props.locale}/${CONSTANTS.MARATHON_CONFIRM}`
          );

        }
       else if(res.error){
        // console.log(res)
             if(res.invalid){
              if(res.invalid.phoneNumber){
                toast.dismiss();
                toast.success(oResourceBundle.error_user_phone_already_exists, {
                  position: toast.POSITION.BOTTOM_CENTER
                });
              }
              else if(res.invalid.firstName){
                // toast.dismiss();
                // toast.success(oResourceBundle.marathon_toast, {
                //   position: toast.POSITION.BOTTOM_CENTER
                //});
                // return alert(res.invalid.firstName.description)
              }
              else if(res.invalid.userName){
                toast.dismiss();
                toast.success(oResourceBundle.userNameExists, {
                  position: toast.POSITION.BOTTOM_CENTER
                });
                // return alert(res.invalid.userName.description)
              }
              else if(res.invalid.lastName){
                // return alert(res.invalid.lastName.description)
              }
              else if(res.invalid.email){
                toast.dismiss();
                
                toast.success(oResourceBundle.error_user_email_already_exists, {
                  position: toast.POSITION.BOTTOM_CENTER
                });
                // return alert(res.invalid.email.description)
              }
             }
            //  return alert(res.error)
          
        }

      });
    }).catch((oError)=>{
      this.setState({ bEnableUpdateBtn: true });
      if (oError) {
        toast.dismiss();
        toast.success(oError.description, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        toast.dismiss();
        toast.success(oResourceBundle.something_went_wrong, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    })
  }

  /**
   * Component Name - MyAccount
   * Update accout button press
   * @param {null}
   */
  handleSignoutAllClicked() {
    this.props.fnSignOutFromAllDevices(
      () => {
        //Device Logout success
      },
      () => {
        //Device Logout failedCHANGE_PASSWORD
      }
    );
    this.setState({ showDialog: false });
  }
  /**
   * Component Name - MyAccount
   * Update accout button press
   * @param {null}
   */
  handleChangePassClicked() {
    sendEvents(
      CONSTANTS.CHANGE_PASSWORD_CATEGORY,
      CONSTANTS.CHANGE_PASSWORD_ACTION,
      CONSTANTS.CHANGE_PASSWORD_LABEL
    );
    if (
      this.props.oUserAccountDetails.registrationSource ===
        CONSTANTS.REGISTRATION_SOURCE_EMAIL ||
      this.props.oUserAccountDetails.registrationSource ===
        CONSTANTS.REGISTRATION_SOURCE_MOBILE
    ) {
      this.props.history.push(
        `/${this.props.locale}/${CONSTANTS.CHANGE_PASSWORD}/`
      );
    }
  }
  /**
   * Component Name - MyAccount
   * Update accout button press
   * @param {oEvent}
   */
   handleMobileInputChanged(oEvent) {
    const { value } = oEvent.target;
    const numberRegex = /^\+?[0-9]*$/;

    if (!numberRegex.test(value)) {
      return;
    }

    if (value.trim() === "") {
      // console.log("Coming to this")
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          mobile: ""
        },
        bMobileValid: true
      });
    } else if (
      !numberRegex.test(value) ||
      value.length < CONSTANTS.MIN_MOBILE_NUMBER ||
      value.length > CONSTANTS.MAX_MOBILE_NUMBER
    ) {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          mobile: oResourceBundle.mobile_invalid
        },
        bMobileValid: false
      });
    } else {
      this.setState({
        bMobileValid: true,
        errorMessage: {
          ...this.state.errorMessage,
          mobile: ""
        }
      });
    }
    this.setState({ mobile: value.trim() }, this.fnSetUpdateButtonEnabled);
  }
  /**
   * Component Name - MyAccount
   * Update accout button press
   * @param {oEvent}
   */
  onPhoneChanged(text, country) {
    try {
      this.setState({
        mobile: text,
        countryCode: country.code,
        showMobileVerification: this.state.savedMobile !== text ? true : false
      }, this.fnSetUpdateButtonEnabled.bind(this));
      if (
        text.length > 5 &&
        phoneUtil.isValidNumber(phoneUtil.parse(text, country.code))
      ) {
        this.setState({
          bMobileValid: true, errorMessage: {
            ...this.state.errorMessage,
            mobile: ""
          }
        });
      } else {
        this.setState({
          bMobileValid: false, errorMessage: {
            ...this.state.errorMessage,
            mobile: oResourceBundle.mobile_invalid
          }
        });
      }
    } catch (e) {
      this.setState({
        bMobileValid: false, errorMessage: {
          ...this.state.errorMessage,
          mobile: oResourceBundle.mobile_invalid
        }
      });
    }
  }

  /**
   * Component Name - MyAccount
   * Key press on search input.
   * @param {object} oEvent - Event hanlder
   * @returns {undefined}
   */
  onPasswordInputkeyPress(oEvent) {
    if (
      oEvent.keyCode === CONSTANTS.ENTER_KEYCODE &&
      this.state.bEnableUpdateBtn
    ) {
      this.handleUpdateBtnClicked();
    }
  }

  handleMobileVerification() {
    if (this.state.showMobileVerification) {
      const data = {
        phonenumber: common.getRawNumber(this.state.mobile),
        requestType: CONSTANTS.OTP_REQUEST_UPDATE_PHONE_NUMBER,
      };
      this.props.sendOTPCode(
        data,
        this.sendSuccess.bind(this),
        this.sendError.bind(this)
      );
    }
  }

  sendSuccess() {
    let oNewUserDetails = {
      phoneNumber: common.getRawNumber(this.state.mobile),
      myAccountUpdate: true,
    };
    this.props.fnSaveNewUserDetails(oNewUserDetails);
    this.props.history.push(
      `/${this.props.locale}/${CONSTANTS.MOBILE_VERIFICATION}`
    );
  }

  sendError(er) {
    let text = oResourceBundle.something_went_wrong;
    try {
      if (
        er.response.data.invalid.phoneNumber.code ===
        CONSTANTS.PHONE_ALREADY_EXISTS
      ) {
        text = oResourceBundle.error_user_phone_already_exists;
      } else if (
        er.response.data.invalid.phoneNumber.code ===
        CONSTANTS.INVALID_PHONE_NUMBER
      ) {
        text = oResourceBundle.mobile_invalid;
      }
    } catch (ex) {
      text = oResourceBundle.something_went_wrong;
    }
    common.showToast(
      CONSTANTS.REGISTER_ERROR_TOAST_ID,
      text,
      toast.POSITION.BOTTOM_CENTER
    );
  }

  /**
   * Component Name - MyAccount
   * It returns jsx to be rendered
   * @param null
   * @returns { undefined }
   */
  render() {
    
    // console.log("propss",this.props)
    let selectedCountryName;
    // console.log("country---",this.state.mobile)
    //const country = CONSTANTS.COUNTRY_LIST_SIGNUP;
    // console.log("USer Data-------------",common.getUserId())
    const country = this.props.aCountryList;
    const language = CONSTANTS.LANGUAGE_LIST_SIGNUP;
    const seoTitle = oResourceBundle.website_meta_title;
    const description = oResourceBundle.website_meta_description;
    const oMetaObject = this.fnConstructMetaTags(
      seoTitle,
      window.location.href,
      description
    );
    const oMetaTags = this.fnUpdateMetaTags(oMetaObject);
    let selected;
    if (country.length > 0) {
     defaultCountryName=common.defaultCountry(this.props.countryCode,this.props.locale)
     selectedCountryName=common.defaultCountry(this.props.dialCode,this.props.locale)
      selected = this.state.selectedCountryCode
        ? country[
            country.findIndex(
              (ele) => ele.key === this.state.selectedCountryCode
            )
          ].text
        :  defaultCountryName;
        this.props.setSelectedCountry(selected)

    }
    // console.log("Country selected----",selected,this.state.selectedCountryCode)

    return (
      <React.Fragment>
        <div className="marathon-bg">
          <img src={marathonbg} className="img-fluid" />
          <h1 className={this.props.locale=='ar' ? "arabic-form" :"english-form"}>{oResourceBundle.marathon_text1}</h1>
          {/* <p>{oResourceBundle.marathon_text2}</p> */}
        </div>
        {oMetaTags}
        {this.props.oUserAccountDetails ? (
          <div
            className="marathon-myaccount-container myaccount-container"
            onClick={this.onContainerClick.bind(this)}
          >
            <div className="myaccount">
              <div className="overlay-title">
              <span style={{color:"#727272"}}>{oResourceBundle.marathon_user_info}</span>
              </div>
              <React.Fragment>
                <section className="form-myaccount" name="formMyAccount">
                  <div className="row">
                    <div className="left-column">
                      <Label>{oResourceBundle.marathon_userName}</Label>
                      <div className="inner-column">
                        <Input
                          type="text"
                          name="Uname"
                          maxlength={maxLength}
                          autoComplete="off"
                          className="first-name"
                          value={this.state.Uname}
                          onKeyDown={this.onPasswordInputkeyPress.bind(this)}
                          onChange={this.handleFormInputs.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="left-column">
                      <Label>{oResourceBundle.first_name}</Label>
                      <div className="inner-column">
                        <Input
                          type="text"
                          name="fname"
                          maxlength={maxLength}
                          autoComplete="off"
                          className="first-name"
                          value={this.state.fname}
                          onKeyDown={this.onPasswordInputkeyPress.bind(this)}
                          onChange={this.handleFormInputs.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="right-column">
                      <Label>{oResourceBundle.last_name}</Label>
                      <div className="inner-column">
                        <Input
                          type="text"
                          name="lname"
                          maxlength={maxLength}
                          autoComplete="off"
                          className="last-name"
                          value={this.state.lname}
                          onKeyDown={this.onPasswordInputkeyPress.bind(this)}
                          onChange={this.handleFormInputs.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="left-column">
                      <Label>{oResourceBundle.email}</Label>
                      <div className="inner-column">
                        <div className="email-conaitner">
                          <div className="email-input">
                            <Input
                              placeholder={oResourceBundle.email}
                              type="email"
                              name="email"
                              // autoComplete="off"
                              disabled={this.props.oUserAccountDetails.email!=null || this.props.oUserAccountDetails.email!=undefined ? true : false}
                              className={[
                                "sign-up-email" /* ,
                                  this.state.bEmailValid ? "" : "error" */,
                              ].join(" ")}
                              value={this.state.email}
                              onKeyDown={this.onPasswordInputkeyPress.bind(
                                this
                              )}
                              onChange={this.handleEmailOnChange.bind(this)}
                            />
                            <span className="error-text">
                              {this.state.emailErrorText}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                    <div className="row">
                      <div className="left-column">
                        <Label>{oResourceBundle.mobile}</Label>
                        <div className="inner-column">
                          <div className="mobile-input-container">
                            <div>
                              <Input
                                type="text"
                                name="mobile"
                                // autoComplete="off"
                                className={"mobile-input"}
                                value={this.state.mobile}
                                onChange={this.handleMobileInputChanged}
                                onKeyDown={this.onPasswordInputkeyPress.bind(
                                  this
                                )}
                              />
                              {this.props.countryCode && (
                                <form className="form" name="form">
                                  <PhoneInput
                                    defaultCountry={this.props.countryCode.toLowerCase()}
                                    onPhoneChanged={this.onPhoneChanged.bind(
                                      this
                                    )}
                                    disablePhone={(this.props.oUserAccountDetails.phoneNumber!='')  ? true : false}
                                    initialValue={this.state.mobile}
                                    page={"settings"}
                                  />
                                </form>
                              )}
                            </div>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="left-column">
                      <Label>{oResourceBundle.country}</Label>
                        <div className="inner-column">
                          <SelectBox
                            className={
                              this.state.showCountrySelectBox ? "open" : ""
                            }
                            show={this.state.showCountrySelectBox}
                            items={country}
                            selected={this.props.dialCodeEvent ? selectedCountryName : selected}
                            showToggle={this.countryShowToggle.bind(this)}
                            onChange={this.handleChangeCountrySelection.bind(
                              this
                            )}
                          />
                        </div>
                      </div>
                     
                    </div>
                  </div>
               
                </section>
              
                {this.state.showDialog ? (
                  <Dialog
                    visible={true}
                    onDialogClosed={this.showSignOutAllCancel.bind(this)}
                    duration={CONSTANTS.RATING_DIALOG_ANIMATION_DURATION}
                    showCloseButton={false}
                    closeOnEsc={true}
                    width={CONSTANTS.SIGNOUTALL_DIALOG_WIDTH}
                    height={CONSTANTS.SIGNOUTALL_DIALOG_HEIGHT}
                  >
                    <div className="signout-dialog-content">
                      <div className="content">
                        <div className="dialog-title">
                          {oResourceBundle.my_account}
                        </div>
                        <div className="dialog-text">
                          {oResourceBundle.sign_out_all_confirm_message}
                        </div>
                      </div>

                      <div className="actions">
                        <Button
                          className="dialog-ok-btn"
                          onClick={this.handleSignoutAllClicked.bind(this)}
                        >
                          {oResourceBundle.confirm}
                        </Button>
                        <Button
                          className="dialog-ok-btn"
                          onClick={this.showSignOutAllCancel.bind(this)}
                        >
                          {oResourceBundle.btn_cancel}
                        </Button>
                      </div>
                    </div>
                  </Dialog>
                ) : null}

                {this.state.showErrorDialog ? (
                  <Dialog
                    visible={true}
                    onDialogClosed={this.hideErrorDialog.bind(this)}
                    duration={CONSTANTS.RATING_DIALOG_ANIMATION_DURATION}
                    showCloseButton={false}
                    closeOnEsc={true}
                    width={CONSTANTS.SIGNOUTALL_DIALOG_WIDTH}
                    height={CONSTANTS.SIGNOUTALL_DIALOG_HEIGHT}
                  >
                    <div className="signout-dialog-content">
                      <div className="content">
                        <div className="dialog-title">
                          {oResourceBundle.my_account}
                        </div>
                        <div className="dialog-text">
                          {this.state.showErrorDialogMessage}
                        </div>
                      </div>

                      <div className="actions">
                        <Button
                          className="dialog-ok-btn"
                          onClick={this.hideErrorDialog.bind(this)}
                        >
                          {oResourceBundle.ok}
                        </Button>
                      </div>
                    </div>
                  </Dialog>
                ) : null}

            
                <div className="my-account-checkboxes">
                  
                  <CheckBox
                    onChange={this.handleCheckBox2.bind(this)}
                    selected={this.state.newsletter2}
                    name="newsletter2"
                    value={this.state.isAdult}
                    text={oResourceBundle.marathon_termsofuse1}
                  />
                  <CheckBox
                    onChange={this.handleCheckBox1.bind(this)}
                    selected={this.state.newsletter1}
                    name="newsletter1"
                    value={this.state.termsOfUse}
                    text={ReactHtmlParser(oResourceBundle.marathon_termsofuse)}
                  />
                   <CheckBox
                    onChange={this.handleCheckBox1.bind(this)}
                    selected={this.state.newsletter3}
                    name="newsletter3"
                    value={this.state.termsOfUse}
                    text={ReactHtmlParser(oResourceBundle.subscribe_to_newsletter)}
                  />

                 
                </div>
                <section className="my-account-action-buttons">
                  <Button
                    className="btn-device-pair"
                    onClick={this.handleUpdateBtnClicked.bind(this)}
                    disabled={!this.state.bEnableUpdateBtn}
                  >
                    {oResourceBundle.marathon_confirm}
                  </Button>
                
                </section>
              </React.Fragment>
            </div>
          </div>
        ) : null}
        {this.props.loading ? <Spinner /> : null}
      </React.Fragment>
    );
  }
}

/**
 * method that maps state to props.
 * Component - MyAccount
 * @param {Object} dispatch - dispatcher from store.
 * @return {Object} - dispatchers mapped to props
 */
const mapDispatchToProps = (dispatch) => {
  return {
    fnFetchUserDetails: (fnSuccess, fnFailed, bShouldDispatch) => {
      dispatch(
        actionTypes.fnFetchUserDetails(fnSuccess, fnFailed, bShouldDispatch)
      );
    },
    setSelectedCountry: (payload) => {
      dispatch({
        type: SET_SELECTEDCOUNTRY,
        payload,
      });
    },
    setEventCountry: (payload) => {
      dispatch({
        type: SET_EVENTCOUNTRY,
        payload,
      });
    },
    handleUpdateAccount: (currentStateValues, fnSuccess, fnFailed) => {
      dispatch(
        actionTypes.fnHandleUpdateAccount(
          currentStateValues,
          fnSuccess,
          fnFailed
        )
      );
    },
    fnSignOutFromAllDevices: (fnSuccess, fnFailed) => {
      dispatch(actionTypes.fnSignOutFromAllDevices(fnSuccess, fnFailed));
    },
    sendOTPCode: (data, sendSuccess, sendError) => {
      dispatch(actionTypes.sendOTPCode(data, sendSuccess, sendError));
    },
    fnSaveNewUserDetails: (newUserDetails) => {
      dispatch(actionTypes.fnSaveNewUserDetails(newUserDetails));
    },
    fnFetchCountryList: (sLanguageCode) => {
      dispatch(actionTypes.fnFetchCountryList(sLanguageCode));
    },
  };
};

/**
 * Component - MyAccount
 * method that maps state to props.
 * @param {Object} state - state from redux store.
 * @return {Object} - state mapped to props
 */
const mapStateToProps = (state) => {
  return {
    locale: state.locale,
    oUserAccountDetails: state.oUserAccountDetails,
    loading: state.loading,
    loginDetails: state.loginDetails,
    aCountryList: state.aCountryList,
    countryCode: state.sCountryCode,
    dialCode : state.dialCode,
    nationalNumber : state.nationalNumber,
    dialCodeEvent : state.dialCodeEvent
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MarathonForm)
);
