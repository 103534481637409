/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */

import React from "react";
import Image from "core/components/Image";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as CONSTANTS from "app/AppConfig/constants";
import oResourceBundle from "app/i18n/";
import "./index.scss";

class AppFooter extends React.Component {
  /**
   * Component Name - AppFooter
   * It is a render method of Footer Component of Website.It will render the footer in the UI.
   * @param { null }
   * @returns { Object }
   */
  render() {
    let imagesURL; 
    if (this.props.platformConfig && this.props.platformConfig.web) {
      const webPlatformData = this.props.platformConfig.web["1.0"];
      const webImagesURL = webPlatformData.Images; 
      if (webImagesURL) {
        imagesURL = webImagesURL; 
      }
    }  
    // console.log(imagesURL,"----------props-------")
    const huaweiAppGURL = imagesURL + "resources/assets/footer/Huawei_AppG1.svg";
    const  appstore  = imagesURL + "resources/assets/footer/apple.svg";
    const GooglePlayStore = imagesURL +  "resources/assets/footer/playstore.svg"
    const Facebook =imagesURL + "resources/assets/footer/facebook.svg"
    const  LinkedIn = imagesURL + "resources/assets/footer/linkedin.svg"
    const  Youtube = imagesURL + "resources/assets/footer/youtube.svg"
    const Twitter = imagesURL + "resources/assets/footer/twitter.svg"
    const Instagram = imagesURL + "resources/assets/footer/Instagram.svg"

    const className = this.props.className + " app-footer";
    return (
      <React.Fragment>
        <footer className={className}>
          <div className="footer-top">

            <div className="footer-top-left">
              {
                <div className="links">
                  <Link
                    aria-label={oResourceBundle.about}
                    key={"about"}
                    className="router-link"
                    to={`/${this.props.locale}/${CONSTANTS.ABOUT}${
                      this.props.locale
                      }`}
                  >{`${oResourceBundle.about}`}</Link>
                  <Link
                    aria-label={oResourceBundle.privacy_policy}
                    key={"privacy"}
                    className="router-link"
                    to={`/${this.props.locale}/${CONSTANTS.PRIVACY_POLICY}${
                      this.props.locale
                      }`}
                  >{`${oResourceBundle.privacy_policy}`}</Link>
                  <Link
                    aria-label={oResourceBundle.terms}
                    key={"terms"}
                    className="router-link"
                    to={`/${this.props.locale}/${CONSTANTS.TERMS_OF_USE}${
                      this.props.locale
                      }`}
                  >{`${oResourceBundle.terms}`}</Link>
                </div>
              }
            </div>
            <div className="footer-top-right">
              <div className="download-app">
                <span>{oResourceBundle.app_available_on}</span>
                <a                
                  href="https://appgallery.huawei.com/app/C100128091"
                  target="_blank"
                  rel="noopener noreferrer" 
                  className="huawei" 
                  aria-label={oResourceBundle.available_google_play}                
                >
                   <img src={huaweiAppGURL} alt="ic-appstore" hideFallback={true} style={{ height: "35px" }} />
                </a>
                <a
               
                  href="https://apps.apple.com/in/app/z5-weyyak-%D9%88%D9%8A%D8%A7%D9%83/id1226514781"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={oResourceBundle.available_app_store}
                >
                  <Image src={appstore} alt="ic-appstore" hideFallback={true}  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.tva.z5"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={oResourceBundle.available_google_play}
                >
                  <Image
                    src={GooglePlayStore}
                    alt={oResourceBundle.available_google_play}
                    hideFallback={true}
                  />
                </a>              
              </div>
              <div className="follow-us">
                <span>{oResourceBundle.follow_us_on}</span>
                <a href="https://www.linkedin.com/company/weyyak">
                  <img src={LinkedIn} hideFallback={true} />
                </a>
                <a href="https://www.instagram.com/z5weyyak/" target="_blank" rel="noopener noreferrer" aria-label={oResourceBundle.instagram} >
                  <img src={Instagram} alt={oResourceBundle.instagram} hideFallback={true} />
                </a>
                <a href="https://twitter.com/Z5weyyak" target="_blank" rel="noopener noreferrer" aria-label={oResourceBundle.twitter} >
                  <img src={Twitter} alt={oResourceBundle.twitter} hideFallback={true} />
                </a>
                <a href="https://www.youtube.com/weyyakcom">
                  <img src={Youtube} hideFallback={true} />
                </a>
                <a href="https://www.facebook.com/weyyakcom" target="_blank" rel="noopener noreferrer" aria-label={oResourceBundle.facebook} >
                  <img src={Facebook} alt={oResourceBundle.facebook} hideFallback={true} />
                </a>
              </div>
            </div>
          </div>
         <div className="footer-bottom">
            <div>
              {oResourceBundle.copyright}
               <span>©</span> 
            </div> 
               {/* <strong className="z5-text"> Z5</strong> */}
         </div>
      </footer>
      </React.Fragment>
    );
  }
}

/**
 * Component - AppFooter
 * method that maps state to props.
 * @param {Object} state - state from redux store.
 * @return {Object} - state mapped to props
 */
const mapStateToProps = state => {
  return {
    aStaticMenuItems: state.aStaticMenuItems,
    platformConfig: state.platformConfig,
    locale: state.locale
  };
};

export default connect(mapStateToProps)(AppFooter);
