import React from "react";
import BaseContainer from "core/BaseContainer/";
import oResourceBundle from "app/i18n/";
import safrawsefra1 from "app/resources/assets/thumbnail/chefomar.png";
import safrawsefra2 from "app/resources/assets/thumbnail/safra-w-sefra-sticker.png";
import "./index.scss";
import Button from "../../../../core/components/Button/";
import { connect } from "react-redux";
import * as CONSTANTS from "../../../AppConfig/constants";
class CookingContestThankyou extends BaseContainer {
  constructor(props) {
    super(props);
  }
  handleSubmitSafra() {
    if (this.props.locale === "en") {
      window.location.href =
        "https://weyyak.com/en/series/232929/Safra-Wa-Soufra";
    } else {
      window.location.href =
        "https://weyyak.com/ar/series/232929/%D8%B3%D9%81%D8%B1%D8%A9-%D9%88%D8%B3%D9%81%D8%B1%D8%A9";
    }
  }

  render() {
    //     const seoTitle = oResourceBundle.website_meta_title;
    // const description = oResourceBundle.website_meta_description;
    //     const oMetaTags = this.fnUpdateMetaTags(oMetaObject);
    //     const oMetaObject = this.fnConstructMetaTags(
    //         seoTitle,
    //         window.location.href,
    //         description
    //       );

    return (
      <React.Fragment>
        {/* {oMetaTags} */}
        <div class="cooking-main-container contest-thankyou">
          <div className="cooking-contest-container">
            <div className="cookingcontest-banner">
              <img src={safrawsefra1} />
            </div>
            {/* <div className="cookiking-contest-banner-sticker">
                    <img src={safrawsefra2} />
                </div> */}
          </div>
          <div className="thankyou-text">
            {oResourceBundle.competition_ended}
          </div>
          <div className="thankyou-text1">
            {oResourceBundle.chef_omar_content}
          </div>
          {/* <div className="thankyou-text1">{oResourceBundle.contest_thankyou_text2}</div> */}
          <Button
            className="btn-device-pair safra-soufra-btn"
            onClick={this.handleSubmitSafra.bind(this)}
          >
            {/* {oResourceBundle.update} */}
            <span>{oResourceBundle.safra_soufra}</span>
          </Button>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    locale: state.locale,
  };
};
export default connect(mapStateToProps)(CookingContestThankyou);
