import React from 'react';
import MarathonLogin from "../MarathonLogin";
import MarathonSignUp from "../MarathonSignUp";
import oResourceBundle from "app/i18n/";
class Marathon extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            showNewUser:true,
            showExistingUser:false,
        }
      }
      handleNewUser(e){
        e.preventDefault()
        e.stopPropagation();
        this.setState({
        showNewUser:true,
          showExistingUser:false
        })
       
        
        // console.log("NEW USER")
      }
      handleExistingUser(e){
        e.preventDefault()
        e.stopPropagation();
        this.setState({
          showNewUser:false,
          showExistingUser:true
        })     
      }
      componentDidMount(){
        if(this.props.pageredirect){
          // console.log("///////////////////////////////")
         this.setState({
          showNewUser:false,
          showExistingUser:true
         })
        }
      }
  render(){
    // console.log("Rendering child",this.props)
    return (
      <React.Fragment>
         {/*  */}
            <div className="join-themarathon">
          <h3>{oResourceBundle.marathon_text7}</h3>
            <div className="join-tabs">
                <div className="join-btns">
                    <div type="button" className={this.props.pageredirect ? "user-btns" : this.state.showNewUser ? "user-btns active" : "user-btns"} onClick={this.handleNewUser.bind(this)}>{oResourceBundle.marathon_newuser}</div>
                    <div type="button" className={this.state.showExistingUser  ? "user-btns active" : this.props.pageredirect ? "user-btns active" : "user-btns"} onClick={this.handleExistingUser.bind(this)}>{oResourceBundle.marathon_existinguser}</div>
                </div>
                {
              this.state.showNewUser ? 
              <div className="new-user">                 
                <MarathonSignUp />
              </div> : ""}
               {this.state.showExistingUser ? 
                <div className="exsiting-user">
                <MarathonLogin />
              </div> : ""} 
                </div>

               <div className='marthan-footer-desc'>
                <h2>{oResourceBundle.marathon5}</h2>
                <p>{oResourceBundle.marathon_update_text}</p>
               </div>
                
               
                </div>
      </React.Fragment>
    );
  }
}

export default Marathon;