import React from "react";
import BaseContainer from "core/BaseContainer/";
import oResourceBundle from "app/i18n/";
import marathonbg from "app/resources/assets/thumbnail/marathon-bg1.png";
import marathonprice from "app/resources/assets/thumbnail/marathon-price1.png";
import marathoncup from "app/resources/assets/thumbnail/marathon-cup1.png";
import "./index.scss";
import Button from "../../../../core/components/Button/";
import { connect } from "react-redux";
import * as CONSTANTS from "../../../AppConfig/constants";
import MarathonLogin from "../MarathonLogin";
import MarathonSignUp from "../MarathonSignUp";
import Marathon from "../Marathon";
class MarathonJoin extends React.PureComponent {
  constructor(props) {
    super(props);
   
  }

  componentDidMount() {

    // document.body.style.overflow = 'hidden';
  }
  render() {
   
    // console.log("rendering",this.props.pageredirect)
    return (
      <React.Fragment>

        <div className="marathon-bg">
          <img src={marathonbg} className="img-fluid" />
          <h1 className={this.props.locale=='ar' ? "arabic-title" : " "}>{oResourceBundle.marathon_text1}</h1>
          <span style={{ visibility: "hidden",
             display:"inline-block"}}>{oResourceBundle.marathon_date}</span>
        </div>
        <div className="marathon-winner-container">
          <div className="marathon-winner-section">
            <div className="marathon-winner-item">
              <div className="image-content"><img src={marathonprice} /></div>
              <div className="description-content">
                <h2>{oResourceBundle.marathon_text3}</h2>
                <p>{oResourceBundle.marathon_text4}</p>
              </div>
            </div>
            <div className="line-marathon"></div>
            <div className="marathon-winner-item">
              
              <div className="image-content"><img src={marathoncup} /></div>
              <div className="description-content">
                <h2>{oResourceBundle.marathon_text5}</h2>
                <p>{oResourceBundle.marathon_text6}</p>
              </div>
             
            </div>
          </div>
        </div>
        
            <Marathon pageredirect={this.props.pageredirect}/>
                         
       
        </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    locale: state.locale,
    pageredirect : state.pageredirect
  };
};
export default connect(mapStateToProps)(MarathonJoin);