import React from "react";
import BaseContainer from "core/BaseContainer/";
import oResourceBundle from "app/i18n/";
import safrawsefra1 from "app/resources/assets/thumbnail/chefomar.png";
import safrawsefra2 from "app/resources/assets/thumbnail/safra-w-sefra-sticker.png";
import marathonbg from "app/resources/assets/thumbnail/marathon-bg1.png";
import marathonprice from "app/resources/assets/thumbnail/marathon-price1.png";
import marathoncup from "app/resources/assets/thumbnail/marathon-cup1.png";
import "./index.scss";
import Button from "../../../../core/components/Button/";
import { connect } from "react-redux";
import * as CONSTANTS from "../../../AppConfig/constants";
class MarathonWinnerAnnounce extends BaseContainer {
  constructor(props) {
    super(props);
  }
   render() {
       return (
      <React.Fragment>

      <div className="marathon-bg marathon-announce">
          <img src={marathonbg} className="img-fluid" />
          <h1 className={this.props.locale=='ar' ? "arabic-announce" : ""}>{oResourceBundle.marathon_text1}</h1>
          {/* <p>{oResourceBundle.marathon_text2}</p> */}
      </div>
      <div className="marathon-winner-container">
        <div className="marathon-winner-section">
            <div className="marathon-winner-item">
              <div className="image-content"><img src={marathonprice} /></div>
              <div className="description-content">
                <h2>{oResourceBundle.marathon_text3}</h2>
                <p>{oResourceBundle.marathon_text4}</p>
              </div>
            </div>
            <div className="line-marathon"></div>

            <div className="marathon-winner-item">
              <div className="image-content"><img src={marathoncup} /></div>
              <div className="description-content">
                <h2>{oResourceBundle.marathon_text5}</h2>
                <p>{oResourceBundle.marathon_text6}</p>
              </div>
            </div>
        </div>
      </div>
      <div className="registration-successs">
        <h3>{oResourceBundle.marathon_winner_announce}</h3>
        <p></p>
        {/* <p>Prepare Yourself for Weyyak's "FIVE MARATHON"</p>
        <p>and Rise to the Challenge</p> */}
      </div>

        
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    locale: state.locale,
  };
};
export default connect(mapStateToProps)(MarathonWinnerAnnounce);
