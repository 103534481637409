import React, { Component } from "react";
import logo from "../../../resources/assets/weyyak-logo1.svg";
import LanguageButton from "../LanguageButton";
import { getDirection } from "app/utility/common";
import "./index.scss";

export default class HeaderBlock extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div
          className={"app-body " + getDirection(this.props.locale)}
          dir={getDirection(this.props.locale)}
          ref="app-body"
        >
          <header className="header">
            <div className="header_bottom">
              <div className="contentLeft">
                <LanguageButton
                  locale={this.props.locale}
                  onLanguageButtonCLick={
                    this.props.onLanguageButtonClickHandler
                  }
                  className={"language_button"}
                />
              </div>
              <div className="contentMiddle">
                <img className="logo" src={logo} alt="Weyyak logo" />
              </div>
              <div className="contentRight">
                <LanguageButton
                  locale={this.props.locale}
                  onLanguageButtonCLick={
                    this.props.onLanguageButtonClickHandler
                  }
                  className={"right-languagebutton"}
                />
              </div>
            </div>
          </header>
        </div>
      </div>
    );
  }
}
