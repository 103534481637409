import React from "react";
import BaseContainer from "core/BaseContainer/";
import oResourceBundle from "app/i18n/";
import "./index.scss";
import Button from "../../../../core/components/Button/";
import { connect } from "react-redux";
import * as CONSTANTS from "../../../AppConfig/constants";
import refreshIocn from "app/resources/assets/thumbnail/refresh-cw.svg";
import * as appURLs from "app/AppConfig/urlConfig";
import { toast } from "core/components/Toaster/";
import * as common from "app/utility/common";
import { SET_LEADERBOARD_DATA } from "../../../store/action/actions";

class MarathonAdmin extends BaseContainer {
  constructor(props) {
    super(props);
    this.state = {
      leaderboardData: [],
      selectedData: [],
      sendData: [],
      limit: 10,
      disableCheckBox: false,
      lastupdate: "",
      bEnableUpdateBtn: false,
    };
  }

  componentDidMount() {
    // For initial data
    this.fetchData();
    // $("input:checkbox").trigger(function() {
    // var bol = $("input:checkbox:checked").length >= 1;     
    // $("input:checkbox").not(":checked").attr("disabled",bol);
    //  });
  }
  handleRefresh() {


    let url = appURLs.MARATHON_GET_30
    fetch(url, {
      method: "GET",
      dataType: "JSON",
      headers: {
        "Content-Type": "application/json;",
      },
    })
      .then((resp) => {
        return resp.json();
      })
      .then((data) => {
        // console.log("data--")
        this.setState({ leaderboardData: data.userResults });
        // console.log(data[0].lastActivityAt)
        this.setState({
          lastupdate: data.lastActivityAt
        })
        return data;
      })
      .catch((error) => {
        console.log(error, "catch the hoop");
      });


  }

  fetchData() {
    let url = appURLs.MARATHON_GET_30
    // const oUserToken = common.getServerCookie(CONSTANTS.COOKIE_USER_TOKEN);
    // let authToken = JSON.parse(oUserToken)
    // let access_token = authToken.authToken
    fetch(url, {
      method: "GET",
      dataType: "JSON",
      headers: {
        "Content-Type": "application/json;",
        // Authorization: `Bearer ${access_token}`,
      },
    })
      .then((resp) => {
        return resp.json();
      })
      .then((data) => {
        // console.log("----------",data.userResults)
        this.setState({ leaderboardData: data.userResults });
        // console.log(data[0].lastActivityAt)
        this.setState({
          lastupdate: data.lastActivityAt
        })
        return data;
      })
      .catch((error) => {
        console.log(error, "catch the hoop");
      });
  }

  handleSelect = (event, index) => {

    const target = event.target;

    var value = target.value;
    if (target.checked) {

      if (this.state.selectedData.length >= this.state.limit) {
        toast.dismiss();
        toast.success(oResourceBundle.limit10, {
          position: toast.POSITION.BOTTOM_CENTER
        });

        // console.log("Every time triggering",)
        event.target.checked = false;
        return;
      }
      // console.log("-------",[...this.state.selectedData, value])

      this.setState({ selectedData: [...this.state.selectedData, value] }, this.fnSetUpdateButtonEnabled);
      //  console.log("Selected DAta",this.state.selectedData)
    } else {
      this.setState({
        selectedData:
          this.state.selectedData.filter((item) => value !== item)
      })
    }
  }

  handlePublish = () => {
    this.setState({ bEnableUpdateBtn: false });
    let usersData = []
    // console.log("selected data------------", this.state.selectedData)
    const leaderBoard = [...this.state.leaderboardData]
    const selectedData = [...this.state.selectedData]
   const itemsFound =  leaderBoard.filter(item => {
      if (selectedData.indexOf(item.id) !== -1) {
        return item
      }
    });

    // console.log("itemsFound >>>>> ", itemsFound)

    let totalData = {
      data: itemsFound
    }
    this.props.setLeaderBoardData(totalData)
    // console.log("users data----",totalData,usersData)
    this.props.history.push({
      pathname: `/${this.props.locale}/${CONSTANTS.MARATHON_ADMIN_LEADERBOARD}`,
      state: totalData
    })
  };
  fnSetUpdateButtonEnabled() {
    if (this.state.selectedData != null || this.state.selectedData != undefined) {
      this.setState({ bEnableUpdateBtn: true });
    } else {
      this.setState({ bEnableUpdateBtn: false });
    }

  }
  convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
  }

  render() {
    // console.log("hello", this.state.selectedData);
    return (
      <React.Fragment>
        <div className="leaderboard-container">
          <h1>{oResourceBundle.marathon_leaderboard2}</h1>
          <div className="leaderboard-header">
            <p>{oResourceBundle.marathon_users}</p>
            <div className="refresh-time">
              <span>
                {oResourceBundle.lastupdate} {this.state.lastupdate.substring(0, 10)}{" "}-{" "}{this.state.lastupdate.substring(11, 19)}{" "}

              </span>
              <button
                className="refresh-btn"
                onClick={this.handleRefresh.bind(this)}
              >
                <img src={refreshIocn} />
                {oResourceBundle.marathon_refresh}
              </button>
            </div>
          </div>
          <ul>
            {this.state.leaderboardData &&
              this.state.leaderboardData.map((item, index) => (
                <li key={index}>
                  <div className="user-li">
                    <div>
                      <span className="user-count">{++index}</span>
                      <span>{item.userName}</span>
                    </div>
                    <div>
                      <span>{this.convertHMS(item.duration)}</span>
                    </div>
                  </div>
                  <label class="custom-checkbox" id="marathon_checkbox">
                    <input
                      type="checkbox"
                      id={item.id}
                      name={item.userName}
                      value={item.id}
                      onChange={(e) => this.handleSelect(e, index)}
                    />
                    <span class="checkmark"></span>
                  </label>
                </li>
              ))}
          </ul>
          <button className="publish-brn" onClick={this.handlePublish} disabled={!this.state.bEnableUpdateBtn}>
            Review
          </button>
        </div>
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {

    setLeaderBoardData: (payload) => {
      dispatch({
        type: SET_LEADERBOARD_DATA,
        payload,
      });
    },
  };
};
const mapStateToProps = (state) => {
  return {
    locale: state.locale,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarathonAdmin);
