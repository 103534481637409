/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */

import React from 'react';
//import appLogo from 'app/resources/assets/header/logo-01.svg';
import './index.scss';
// import logo from '../../../resources/assets/weyyak-logo.svg';
import logo from '../../../resources/assets/weyyak-logo1.svg';


/**
   * Component Name - HeaderContentMiddle
   * This is an functional Component and it is Middle Part in Header Section that will consist of logo of Website.
   * @param { Object } Props - Properties to the Component.
   * @returns { Object }
   */

const headerContentMiddle = React.memo((props) => (
	<div className="logo-container" onClick={() => props.onLogoClick()}>
		{/* <img className="logo" src="https://contents-uat.weyyak.z5.com/resources/assets/header/logo-01.svg" alt="Weyyak logo" /> */}
		<img className="logo" src={logo} alt="Weyyak logo" />

	</div>
));
export default headerContentMiddle;
