import React from "react";
import BaseContainer from "core/BaseContainer/";
import oResourceBundle from "app/i18n/";
import safrawsefra1 from "app/resources/assets/thumbnail/chefomar.png";
import safrawsefra2 from "app/resources/assets/thumbnail/safra-w-sefra-sticker.png";
import marathonbg from "app/resources/assets/thumbnail/marathon-bg1.png";
import marathonprice from "app/resources/assets/thumbnail/marathon-price1.png";
import marathoncup from "app/resources/assets/thumbnail/marathon-cup1.png";
import "./index.scss";
import Button from "../../../../core/components/Button/";
import { connect } from "react-redux";
import * as CONSTANTS from "../../../AppConfig/constants";
class MarathonTermsOfUse extends BaseContainer {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>

                <div className="marathon-tac-container">
                    <h1>{oResourceBundle.marathon_terms}</h1>

                    <p>{oResourceBundle.marathon_terms1}</p>

                    <p>{oResourceBundle.marathon_terms2}</p>

                    <p>{oResourceBundle.marathon_terms3}</p>

                    <p>{oResourceBundle.marathon_terms4}</p>

                    <ul>
                        <li><p>{oResourceBundle.marathon_terms8}</p></li>
                        <li><p>{oResourceBundle.marathon_terms9}</p></li>
                    </ul>                    

                    <p>{oResourceBundle.marathon_terms5}</p>

                    <p>{oResourceBundle.marathon_terms6}</p>

                    <p>{oResourceBundle.marathon_terms7}</p>
                </div>

            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        locale: state.locale,
    };
};
export default connect(mapStateToProps)(MarathonTermsOfUse);
