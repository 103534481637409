import React from "react";
import BaseContainer from "core/BaseContainer/";
import oResourceBundle from "app/i18n/";
import "./index.scss";
import Button from "../../../../core/components/Button";
import { connect } from "react-redux";
import * as CONSTANTS from "../../../AppConfig/constants";
import refreshIocn from "app/resources/assets/thumbnail/refresh-cw.svg";
import starfull from "app/resources/assets/thumbnail/star-full.svg"
import marathonbg from "app/resources/assets/thumbnail/marathon-bg1.png";
import marathonprice from "app/resources/assets/thumbnail/marathon-price1.png";
import marathoncup from "app/resources/assets/thumbnail/marathon-cup1.png";
import * as common from "app/utility/common";
import * as appURLs from "app/AppConfig/urlConfig";

let routeData=[];
class MarathonAdminLeaderBoard extends BaseContainer {
  constructor(props) {
    super(props);
    this.state = {
      tenUsersData: [],
      lastupdate :"",
     
    }
  }
  componentDidMount() {
    //  console.log("Data--- from --------routing---",this.props.location)
  //  this.setState({
  //   tenUsersData : this.props.location.state.data
  //  })
   routeData=this.props.leaderBoardData ? this.props.leaderBoardData : this.props.location.state.data;
  //  console.log("leaderboard-----",routeData)
this.setState({
  tenUsersData : routeData.data
})
    // console.log(routeData)
    // this.fetchData()
  }
  // GET request for Top 10 Users
  fetchData() {
  //  console.log("Coming to this",this.props.location.state.data)
   this.setState({
    tenUsersData : this.props.location.state.totalData
   })

  }
  handlePublish (){
   
    let url = appURLs.MARATHON_POST_TEN;
    fetch(url, {
     method: 'POST',
     headers: {
       "Content-Type": "application/json;",
       
     },
     body: JSON.stringify(routeData)
   }).then((result) => {
     result.json().then((res) => {
      // console.log("result----",res)
       if(res.status==1){
        //  console.log("Coming to this response successful-------")
         window.history.back()
        //  this.props.history.push({pathname: `/${this.props.locale}/marathon-admin`});
         // window.location.reload();
 
       }
       // console.log('res', res)
     })
     .catch((err)=>{
       // toast.dismiss();
       //   toast.success(oResourceBundle.something_went_wrong, {
       //     position: toast.POSITION.BOTTOM_CENTER
       //   });
     })
   })
  }
  convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours   = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds; // Return is HH : MM : SS
}


  render() {
    // console.log("tenusers data-------",this.state.tenUsersData)
    //  console.log("leaderboard data-----",this.props.leaderBoardData)
    return (
      <React.Fragment>
        <div className="marathon-bg">
          <img src={marathonbg} className="img-fluid" />
          <h1>{oResourceBundle.marathon_leaderboard}</h1>
          <p>{oResourceBundle.marathon_leaderboard1}</p>
        </div>
        <div className="leaderboard-container">
          <h1>LEADERBOARD</h1>
          <div className="leaderboard-header">
            <p>{oResourceBundle.marathon_top10}</p>
            {/* <p>{oResourceBundle.lastupdate}  {this.state.lastupdate.substring(0,10)}{" "}-{" "}{this.state.lastupdate.substring(11,19)}{" "}</p> */}
          </div>
          {

          }
          <ul>
            {
              this.state.tenUsersData && this.state.tenUsersData.map((item,index)=>(

            <li>
              <div className="user-li">
                <div>
                  <span className="user-count">{++index}</span>
                  <span className="user-name">{item.userName}</span>
                  {/* <span><img src={starfull} width="14" /></span> */}
                </div>
                <div>
                  <span>{this.convertHMS(item.duration)}</span>
                </div>
              </div>

            </li>
              ))
            }
            
          </ul>
          <button className="publish-brn" onClick={this.handlePublish}>
            {oResourceBundle.marathon_publish}
          </button>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    locale: state.locale,
    leaderBoardData : state.leaderBoardData
  };
};
export default connect(mapStateToProps)(MarathonAdminLeaderBoard);
