import React from "react";
import BaseContainer from "core/BaseContainer/";
import oResourceBundle from "app/i18n/";
import "./index.scss";
import Button from "../../../../core/components/Button/";
import { connect } from "react-redux";
import * as CONSTANTS from "../../../AppConfig/constants";
import refreshIocn from "app/resources/assets/thumbnail/refresh-cw.svg";
import starfull from "app/resources/assets/thumbnail/star-full.svg"
import marathonbg from "app/resources/assets/thumbnail/marathon-bg1.png";
import marathonprice from "app/resources/assets/thumbnail/marathon-price1.png";
import marathoncup from "app/resources/assets/thumbnail/marathon-cup1.png";
import * as common from "app/utility/common";
import * as appURLs from "app/AppConfig/urlConfig";
import { duration } from "moment";
class MarathonLeaderBoard extends BaseContainer {
  constructor(props) {
    super(props);
    this.state = {
      tenUsersData: [],
      lastupdate: "",
      firstName:"",
      lastName:"",
      nickName:"",
      myRank:null,
      duration:null
    }
  }
  componentDidMount() {
    this.fetchData()
  }
  // GET request for Top 10 Users
  fetchData() {
    let url = appURLs.MARATHON_GET_TEN
    // const oUserToken = common.getServerCookie(CONSTANTS.COOKIE_USER_TOKEN);
    // let authToken = JSON.parse(oUserToken)
    // let access_token = authToken.authToken
    // let url = appURLs.MARATHON_GET_30
    fetch(url, {
      method: "GET",
      dataType: "JSON",
      headers: {
        "Content-Type": "application/json;",
        // Authorization: `Bearer ${access_token}`,

      },
    })
      .then((resp) => {
        return resp.json();
      })
      .then((data) => {
        // console.log("Users data--------",data)
        this.setState({ tenUsersData: data.userResults});
        this.setState({
          lastupdate: data.lastActivityAt,
          firstName:data.firstName,
          lastName:data.lastName,
          nickName:data.userName,
          duration:data.duration,
          myRank:data.rank
        })
        return data;
      })
      .catch((error) => {
        console.log(error, "catch the hoop");
      });

  }
  convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
  }
handlewatchNow (){
  this.props.history.push(`/${this.props.locale}`);
}
convertHMS(value) {
  const sec = parseInt(value, 10); // convert value to number if it's string
  let hours   = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
  let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  return hours+':'+minutes+':'+seconds; // Return is HH : MM : SS
}
  render() {
    return (
      <React.Fragment>
        <div className="marathon-bg marathon-leader">
          <img src={marathonbg} className="img-fluid" />
          <h1 className={this.props.locale=='ar' ? "arabic-leader":""}>{oResourceBundle.marathon_leaderboard}</h1>
          <p>{oResourceBundle.marathon_leaderboard1}</p>
          <h3><span style={{font:"normal normal normal 19px/35px Changa"}}>{this.state.nickName}</span><span> | </span><span style={{color: "#FFFFFF"}}>{this.state.firstName}{this.state.lastName}</span></h3>
        </div>
        <div className="leaderboard-container">
          {/* <h1>LEADERBOARD</h1> */}
          <div class="marthon-watchtime">
            <span>{oResourceBundle.marathon_watchtime}: {this.convertHMS(this.state.duration)}</span>
            <span>{oResourceBundle.marathon_rank} :{this.state.myRank}</span>
        </div>
          <div className="leaderboard-header">
            <p className="top-ten">{oResourceBundle.marathon_top10}</p>
            <p>{oResourceBundle.lastupdate}  {this.state.lastupdate.substring(0, 10)}{" "}-{" "}{this.state.lastupdate.substring(11, 19)}{" "}</p>
          </div>
          {

          }
          <ul>
            {
              this.state.tenUsersData && this.state.tenUsersData.map((item, index) => (

                <li>
                  <div className="user-li">
                    <div>
                      <span className="user-count">{++index}</span>
                      <span className="user-name">{item.userName}</span>
                      {index <= 5 ? <span><img src={starfull} width="14" /></span> : ""}
                    </div>
                    <div>
                      <span>{this.convertHMS(item.duration)}</span>
                    </div>
                  </div>

                </li>
              ))
            }

          </ul>
          <button className="browse-btn" onClick={this.handlewatchNow.bind(this)}>
              {oResourceBundle.marathon_watch}
          </button>
     

        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    locale: state.locale,
  };
};
export default connect(mapStateToProps)(MarathonLeaderBoard);
